import React from "react";

export default function call() {
  return (
    <svg
      width="101"
      height="100"
      viewBox="0 0 101 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        opacity="0.1"
        x="0.166748"
        width="100"
        height="100"
        rx="50"
        fill="#855FCE"
      />
      <path
        d="M40.3034 47.7817C42.9434 52.97 47.1967 57.2233 52.3851 59.8633L56.4184 55.83C56.9317 55.3167 57.6467 55.17 58.2884 55.3717C60.3417 56.05 62.5417 56.4167 64.8334 56.4167C65.3196 56.4167 65.786 56.6098 66.1298 56.9536C66.4736 57.2975 66.6667 57.7638 66.6667 58.25V64.6667C66.6667 65.1529 66.4736 65.6192 66.1298 65.963C65.786 66.3068 65.3196 66.5 64.8334 66.5C56.5675 66.5 48.6401 63.2164 42.7953 57.3715C36.9504 51.5266 33.6667 43.5992 33.6667 35.3333C33.6667 34.8471 33.8599 34.3808 34.2037 34.037C34.5475 33.6932 35.0139 33.5 35.5001 33.5H41.9167C42.403 33.5 42.8693 33.6932 43.2131 34.037C43.5569 34.3808 43.7501 34.8471 43.7501 35.3333C43.7501 37.625 44.1167 39.825 44.7951 41.8783C44.9967 42.52 44.8501 43.235 44.3367 43.7483L40.3034 47.7817Z"
        fill="#855FCE"
      />
    </svg>
  );
}
