import Retail from "../icons/retail";
import ad from "../icons/ad";
import apple from "../icons/apple";
import content from "../icons/content";
import facebook from "../icons/facebook";
import manpower from "../icons/manpower";
import message from "../icons/message";
import seo from "../icons/seo";
import twitter from "../icons/twitter";

const features = [
  {
    name: "Retail Sales",
    description:
      "Gathering and analyzing data on target markets, competitors, and industry trends to inform marketing strategies.",
    icon: Retail,
  },
  {
    name: "Merchandising & Branding",
    description:
      "Developing and implementing brand guidelines, including logos, messaging, and visual elements, to establish a strong and consistent brand image.",
    icon: apple,
  },
  {
    name: "Online & Retails Advertising",
    description:
      "Planning and executing advertising campaigns, including media buying and placement, to reach targeted audiences and drive sales.",
    icon: ad,
  },
  {
    name: "Staffing & Manpower",
    description:
      "Managing relationships with media outlets and other stakeholders, and crafting and distributing press releases and other communications to generate positive media coverage.",
    icon: manpower,
  },
  {
    name: "Social Media Management",
    description:
      "Developing and implementing social media strategies, content creation and distribution, to engage and grow audiences on social media platforms.",
    icon: facebook,
  },
  {
    name: "Content Marketing",
    description:
      "Creating and distributing valuable, relevant, and consistent content to attract and retain a clearly defined audience and drive profitable customer action.",
    icon: content,
  },
  {
    name: "Web & Mobile Development",
    description:
      "Developing and executing email marketing campaigns to promote products or services and nurture relationships with customers and leads.",
    icon: message,
  },
  {
    name: "Technology Enablement",
    description:
      "Identifying and collaborating with social media influencers to promote products or services to their followers.",
    icon: twitter,
  },

  {
    name: "Sales Analytics & Reporting",
    description:
      "Optimizing website and online content to improve search engine rankings and drive traffic to a website.",
    icon: seo,
  },
];

export default function Services() {
  return (
    <div id="services" className="relative  bg-white py-24 sm:py-32 lg:py-40">
      <div className="text-center">
        <div className="h-full md:bg-[url('../public/img/services-bg1.svg')] bg-left bg-no-repeat bg-contain">
          <div className="md:bg-[url('../public/img/services-right-bg.svg')] bg-right bg-no-repeat bg-contain">
            <h2 className="text-base uppercase font-medium text-black">
              Our Services
            </h2>
            <p className="mt-2 text-2xl font-semibold text-gray-900">
              What We Do
            </p>
            <p className="px-6 mx-auto mt-5 max-w-screen-md text-base text-[#45464F]">
              we pride ourselves on our ability to customize our approach to
              meet the unique needs of each of our clients. Whether you're a
              small startup or a large corporation, we have the expertise and
              resources to help you elevate your business and achieve success.
            </p>
          </div>
        </div>
        <div className="my-20 mx-auto max-w-md sm:max-w-3xl lg:max-w-7xl px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name}>
                <div className="flow-root rounded-lg border border-2 border-white hover:border-slate-200 cursor">
                  <div className="p-5">
                    <div className="w-full flex justify-center items-center py-5">
                      <div className="flex justify-center items-center rounded-full bg-black w-[50px] h-[50px] drop-shadow-2xl">
                        <feature.icon
                          className="text-white"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                    <h3 className="py-3 text-2xl font-normal leading-8  tracking-wide text-[#00174B]">
                      {feature.name}
                    </h3>
                    <p className="text-base leading-7 text-[#45464F]">
                      {feature.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
