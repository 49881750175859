import React from "react";

export default function SendMessage() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3151 10.1969L3.78308 11.4519C3.69649 11.4664 3.61522 11.5034 3.54746 11.5592C3.47971 11.615 3.42784 11.6877 3.39708 11.7699L0.80008 18.7279C0.55208 19.3679 1.22108 19.9779 1.83508 19.6699L19.8351 10.6699C19.9595 10.6076 20.0641 10.5118 20.1372 10.3934C20.2103 10.275 20.249 10.1386 20.249 9.99943C20.249 9.86027 20.2103 9.72385 20.1372 9.60543C20.0641 9.48702 19.9595 9.39128 19.8351 9.32893L1.83508 0.328928C1.22108 0.0219284 0.55208 0.631928 0.80008 1.27093L3.39808 8.22893C3.42884 8.31116 3.48071 8.38383 3.54846 8.43966C3.61622 8.49548 3.69749 8.53248 3.78408 8.54693L11.3161 9.80193C11.3631 9.80943 11.4058 9.83343 11.4367 9.86963C11.4676 9.90583 11.4846 9.95185 11.4846 9.99943C11.4846 10.047 11.4676 10.093 11.4367 10.1292C11.4058 10.1654 11.3631 10.1894 11.3161 10.1969H11.3151Z"
        fill="#FEFBFF"
      />
    </svg>
  );
}
