import React from "react";

export default function seo() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1835 4H2.8165C2.36556 4 2 4.36556 2 4.8165V19.1835C2 19.6344 2.36556 20 2.8165 20H21.1835C21.6344 20 22 19.6344 22 19.1835V4.8165C22 4.36556 21.6344 4 21.1835 4Z"
        fill="white"
        stroke="white"
        stroke-width="4"
      />
      <path
        d="M8 9.47403C7 8.00003 5.249 8.96904 5.3745 10.484C5.5 12 7.5 12 7.6245 13.516C7.75 15.031 6 16 5 14.5255M13 9.00003H11V15.5H13M11 12.5H13"
        stroke="#00174B"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19 10.5C19 9.67157 18.3284 9 17.5 9C16.6716 9 16 9.67157 16 10.5V14C16 14.8284 16.6716 15.5 17.5 15.5C18.3284 15.5 19 14.8284 19 14V10.5Z"
        stroke="#00174B"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
