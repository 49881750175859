import React from "react";

export default function location() {
  return (
    <svg
      width="101"
      height="100"
      viewBox="0 0 101 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        opacity="0.1"
        x="0.833496"
        width="100"
        height="100"
        rx="50"
        fill="#2F80ED"
      />
      <path
        d="M55.1418 66.0875L45.3335 62.65L36.8085 65.95C36.1974 66.1944 35.6321 66.1254 35.1127 65.7428C34.5932 65.3615 34.3335 64.85 34.3335 64.2083V38.5416C34.3335 38.1444 34.4484 37.793 34.6782 37.4875C34.9067 37.1819 35.2196 36.9527 35.6168 36.8L44.1418 33.9125C44.5391 33.7902 44.9363 33.7212 45.3335 33.7053C45.7307 33.6906 46.1279 33.7597 46.5252 33.9125L56.3335 37.35L64.8585 34.05C65.4696 33.8055 66.0349 33.874 66.5543 34.2553C67.0738 34.6379 67.3335 35.15 67.3335 35.7916V61.4583C67.3335 61.8555 67.2186 62.2069 66.9888 62.5125C66.7603 62.818 66.4474 63.0472 66.0502 63.2L57.5252 66.0875C57.1279 66.2097 56.7307 66.2781 56.3335 66.2928C55.9363 66.3087 55.5391 66.2402 55.1418 66.0875ZM54.5002 62.0083V40.5583L47.1668 37.9916V59.4416L54.5002 62.0083Z"
        fill="#0053DB"
      />
    </svg>
  );
}
