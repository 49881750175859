import React from "react";

export default function content() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5586 19.4839H12.0208L8.26842 23.2363C7.75925 23.7454 7.13364 24 6.39159 24C5.65042 24 5.02525 23.7454 4.51608 23.2363L0.76375 19.4839C0.254583 18.9748 0 18.3438 0 17.5911C0 16.8385 0.254583 16.2075 0.76375 15.6984L4.48288 11.9792V4.44137L19.5586 19.4839ZM5.6119 1.88447C7.98063 0.379112 10.5428 -0.224361 13.2985 0.0740546C16.0551 0.373356 18.4296 1.5192 20.422 3.51159C22.4144 5.50398 23.5598 7.87802 23.8582 10.6337C24.1575 13.3903 23.5545 15.953 22.0491 18.3217L20.1231 16.3957C21.1193 14.5804 21.4682 12.6598 21.1698 10.6337C20.8705 8.60857 19.9903 6.86545 18.5292 5.40436C17.0681 3.94328 15.325 3.06308 13.2999 2.76378C11.2738 2.46537 9.35316 2.81425 7.53788 3.81045L5.6119 1.88447ZM9.53027 5.80284C10.7921 5.4265 12.065 5.34902 13.349 5.57039C14.633 5.79177 15.7399 6.36735 16.6697 7.29713C17.5994 8.22691 18.1693 9.32848 18.3791 10.6018C18.5899 11.8743 18.5071 13.1415 18.1307 14.4033L15.8727 12.1453C15.8727 11.5918 15.7899 11.0548 15.6243 10.5341C15.4578 10.0143 15.1864 9.56624 14.8101 9.1899C14.4116 8.79142 13.9525 8.50364 13.4327 8.32653C12.912 8.14943 12.3639 8.06088 11.7883 8.06088L9.53027 5.80284Z"
        fill="white"
      />
    </svg>
  );
}
