import React from "react";

export default function Retail() {
  return (
    <svg
      width="26"
      height="18"
      viewBox="0 0 26 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-0.135498 8.13337H6.3645V10.7334H-0.135498V8.13337ZM20.8855 10.4084C21.5615 9.38137 21.9645 8.15937 21.9645 6.83337C21.9645 3.24537 19.0525 0.333374 15.4645 0.333374C11.8765 0.333374 8.9645 3.24537 8.9645 6.83337C8.9645 10.4214 11.8765 13.3334 15.4645 13.3334C16.7905 13.3334 18.0125 12.9304 19.0525 12.2544L24.0315 17.2334L25.8645 15.4004L20.8855 10.4084ZM15.4645 10.7334C13.3195 10.7334 11.5645 8.97837 11.5645 6.83337C11.5645 4.68837 13.3195 2.93337 15.4645 2.93337C17.6095 2.93337 19.3645 4.68837 19.3645 6.83337C19.3645 8.97837 17.6095 10.7334 15.4645 10.7334ZM-0.135498 1.63337H6.3645V4.23337H-0.135498V1.63337ZM-0.135498 14.6334H12.8645V17.2334H-0.135498V14.6334Z"
        fill="#FFF"
      />
    </svg>
  );
}
