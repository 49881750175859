import React from "react";

export default function apple() {
  return (
    <svg
      width="19"
      height="22"
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4434 21.1173C14.2457 22.2783 12.9381 22.095 11.6794 21.5451C10.3473 20.9829 9.12528 20.9585 7.7199 21.5451C5.96012 22.3027 5.03135 22.0828 3.98037 21.1173C-1.98332 14.9703 -1.10343 5.60929 5.66682 5.26711C7.31662 5.35266 8.46536 6.17144 9.43079 6.24477C10.8728 5.95147 12.2538 5.10824 13.7936 5.21823C15.6389 5.36488 17.0321 6.09812 17.9486 7.41795C14.1358 9.70322 15.0401 14.7259 18.5352 16.1313C17.8386 17.9644 16.9343 19.7853 15.4311 21.1296L15.4434 21.1173ZM9.30859 5.19379C9.12528 2.46858 11.3372 0.219972 13.8791 0C14.2335 3.15294 11.0195 5.49931 9.30859 5.19379Z"
        fill="white"
      />
    </svg>
  );
}
