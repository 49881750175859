import { useState } from "react";
import { Link } from "react-scroll";

const navigation = [
  { name: "Home", href: "home" },
  { name: "About", href: "about" },
  { name: "Services", href: "services" },
  { name: "Contact", href: "contact" },
];

export default function Example() {
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 250) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeBackground);
  return (
    <header
      className={
        navbar
          ? "fixed w-full z-[1000] bg-white border-b border-gray-200"
          : "fixed w-full z-[1000] bg-opacity-70  bg-white border-b border-gray-200"
      }
    >
      <nav className="mx-auto max-w-7xl px-6 lg:px-8" aria-label="Top">
        <div className="flex w-full items-center justify-between py-6 border-b border-gray-100 py-6 lg:border-none">
          <div className="flex items-center">
            <a href="/">
              <img
                className="h-8 md:h-10 w-auto"
                src="/img/staffnow_logo.svg"
                alt=""
              />
            </a>
            <div className="ml-10 hidden space-x-8 lg:block">
              {navigation.map((link) => (
                <Link
                  to={link.href}
                  spy={true}
                  smooth={true}
                  offset={-150}
                  duration={500}
                  key={link.name}
                  className="text-base font-medium text-gray-600 hover:text-black"
                >
                  {link.name}
                </Link>
              ))}
            </div>
          </div>
          <div className=" space-x-4">
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={-180}
              duration={500}
              className="flex justify-center items-center rounded-lg border border-transparent bg-[#0053DB] py-1 md:py-2 px-3 md:px-4 text-base font-bold text-white hover:bg-opacity-75"
            >
              <img
                className="h-6 md:h-9 md w-auto"
                src="/img/chat_icon.svg"
                alt=""
              />
              <p className="px-2 text-xs md:text-base"> Talk with us now</p>
            </Link>
          </div>
        </div>
        <div className="flex flex-wrap justify-center gap-x-6 py-4 lg:hidden">
          {navigation.map((link) => (
            <Link
              to={link.href}
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
              key={link.name}
              className="text-base font-medium text-gray-600 hover:text-black"
            >
              {link.name}
            </Link>
          ))}
        </div>
      </nav>
    </header>
  );
}
