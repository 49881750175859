import React from 'react'

export default function Hero () {
  return (
    
    <div id='home' className="lg:relative bg-[url('../public/img/curves-5.svg')] bg-hero lg:bg-left-bottom bg-no-repeat object-cover">
        {/* <div className='mt-32'> */}
        <div className="mx-auto w-full max-w-7xl pt-16 pb-20 text-center lg:py-48 lg:text-left">
          <div className="mt-[170px] lg:m-0 px-6 sm:px-8 lg:w-1/2 xl:pr-16">
            <p className='uppercase text-[#45464F] font-semibold pb-6'>Expert Marketing Management</p>
            <h1 className="leading-normal font-light font-poppins tracking-wider text-[#1B1B1F] text-5xl">
            Elevate Your Marketing Strategy with Our Expert Management Services
            </h1>
            <p className="mx-auto mt-3 max-w-md text-lg text-[#45464F] sm:text-base md:mt-5 md:max-w-3xl">
            We helps businesses develop and implement effective marketing strategies to reach their target audience and achieve their marketing and business goals.
            </p>
            <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
              <div className="rounded-xl drop-shadow-2xl">
                <a
                  href="/"
                  className="flex w-full items-center justify-center rounded-lg border border-transparent bg-[#0053DB] px-6 py-3 text-base font-medium text-white hover:bg-indigo-700 md:py-3 md:px-6 md:text-lg"
                  >
                  Who we are?
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="relative h-64 w-full sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:h-full lg:w-1/2">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="/img/hero_image.png"
            alt=""
            />
        </div>
        {/* </div> */}
    </div>
  )
}
