import React from "react";

export default function mail() {
  return (
    <svg
      width="101"
      height="100"
      viewBox="0 0 101 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        opacity="0.1"
        x="0.5"
        width="100"
        height="100"
        rx="50"
        fill="#27AE60"
      />
      <path
        d="M65.5002 35.3333H36.1669C34.1502 35.3333 32.5002 36.9833 32.5002 38.9999V60.9999C32.5002 63.0166 34.1502 64.6666 36.1669 64.6666H65.5002C67.5169 64.6666 69.1669 63.0166 69.1669 60.9999V38.9999C69.1669 36.9833 67.5169 35.3333 65.5002 35.3333ZM64.7669 43.1249L52.7769 50.6233C51.5852 51.3749 50.0819 51.3749 48.8902 50.6233L36.9002 43.1249C36.7164 43.0217 36.5554 42.8823 36.427 42.7151C36.2987 42.5479 36.2055 42.3563 36.1533 42.1521C36.1011 41.9479 36.0908 41.7351 36.1232 41.5268C36.1556 41.3185 36.2299 41.1189 36.3416 40.9401C36.4533 40.7614 36.6002 40.6071 36.7732 40.4867C36.9463 40.3664 37.142 40.2824 37.3485 40.2398C37.555 40.1972 37.7679 40.197 37.9745 40.2392C38.1811 40.2813 38.3769 40.3649 38.5502 40.4849L50.8336 48.1666L63.1169 40.4849C63.2902 40.3649 63.4861 40.2813 63.6927 40.2392C63.8992 40.197 64.1122 40.1972 64.3187 40.2398C64.5251 40.2824 64.7208 40.3664 64.8939 40.4867C65.067 40.6071 65.2138 40.7614 65.3256 40.9401C65.4373 41.1189 65.5116 41.3185 65.544 41.5268C65.5763 41.7351 65.5661 41.9479 65.5139 42.1521C65.4616 42.3563 65.3685 42.5479 65.2401 42.7151C65.1117 42.8823 64.9507 43.0217 64.7669 43.1249Z"
        fill="#27AE60"
      />
    </svg>
  );
}
