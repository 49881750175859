import React from "react";

export default function Location() {
  // const LATTITUDE = 25.184242;
  // const LONGITUDE = 55.272430;
  return (
    <div className="relative w-[100vw]">
      <div className="max-w-screen-2xl mx-auto z-auto px-4">
        <iframe
          title="google map"
          // style={{ filter: " grayscale(100%) invert(92%) contrast(83%)" }}
          className="w-full h-[450px] rounded-lg"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=+25.184242+,+55.272430+&t=k&z=13&ie=UTF8&iwloc=&output=embed"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
        ></iframe>
      </div>
      <div className="absolute right-0 -bottom-10 -z-10">
        <img
          className="w-full h-full"
          alt="map background"
          src="img/Rectangle-map.svg"
        />
      </div>
    </div>
  );
}
