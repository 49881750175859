import React, { useState } from "react";
import call from "../icons/call";
import mail from "../icons/mail";
import location from "../icons/location";
import ChatCircle from "../icons/chat-circle";
import SendMessage from "../icons/send-message";
import { sendEmail } from "../services/contactUsService";
import Alert from "../components/Alert";

const features = [
  {
    name: "Call Us",
    description: "Monday – Friday 9-6 PM +971 50597 1449",
    icon: call,
  },
  {
    name: "Email",
    description: "info@staffnow.ae",
    icon: mail,
  },
  {
    name: "Location",
    description: "Bur Dubai, United Arab Emirates",
    icon: location,
  },
];

export default function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = { name, email, phone, message, company };
    sendEmail(payload, onFinish);
    // console.log(payload);
  };
  const onFinish = () => {
    setEmail("");
    setName("");
    setCompany("");
    setMessage("");
    setPhone("");
    setAlert(true);
  };
  return (
    <div id="contact" className="w-[100vw]">
      <div className="relative w-[100vw] bg-[url('../public/img/contact_bg.svg')] bg-left bg-no-repeat bg-cover lg:h-[450px]">
        <div className=" mx-auto max-w-md px-6 sm:max-w-3xl lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-24 lg:px-8 py-5">
          <div className="mt-20">
            <div>
              <p className="text-gray-200 uppercase font-medium">Contact Us</p>
            </div>
            <div className="mt-6 sm:max-w-xl">
              <h1 className="text-3xl font-semibold tracking-wide text-gray-200">
                Get in Touch With Us
              </h1>
              <p className="mt-6 text-lg text-gray-300">
                Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui
                lorem cupidatat commodo.
              </p>
            </div>
          </div>

          <div className="lg:absolute right-0 bg-white z-10 border-2 border-indigo-100 rounded-lg mt-6 lg:-top-24 lg:max-w-xl xl:max-w-3xl w-full">
            <div className="px-6 sm:mx-auto sm:max-w-3xl lg:h-full lg:max-w-none">
              <div className="flex justify-start items-center pt-10 pb-5">
                <ChatCircle />
                <p className="text-lg md:text-2xl  pl-3">
                  Your Marketing, Our Priority
                </p>
              </div>
              <form action="#" method="POST" onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                  <div>
                    <label htmlFor="full-name" className="sr-only">
                      Your Name
                    </label>
                    <input
                      type="text"
                      name="full-name"
                      id="full-name"
                      autoComplete="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="block w-full border-b border-gray-300 py-3 px-4 placeholder-gray-500 focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="Full name"
                      required
                    />
                    <p className="invisible peer-invalid:visible text-red-700 font-light">
                      Please enter your name
                    </p>
                  </div>
                  <div>
                    <label htmlFor="email" className="sr-only">
                      Email
                    </label>
                    <input
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      className="block w-full border-b border-gray-300 py-3 px-4 placeholder-gray-500 focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="Email"
                    />
                  </div>
                  <div>
                    <label htmlFor="phone" className="sr-only">
                      Company
                    </label>
                    <input
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      type="text"
                      name="company"
                      id="company"
                      autoComplete="company"
                      className="block w-full border-b border-gray-300 py-3 px-4 placeholder-gray-500 focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="Company"
                    />
                  </div>
                  <div>
                    <label htmlFor="phone" className="sr-only">
                      Your Phone
                    </label>
                    <input
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      type="tel"
                      name="phone"
                      id="phone"
                      autoComplete="tel"
                      className="block w-full border-b border-gray-300 py-3 px-4 placeholder-gray-500 focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="Phone"
                      required

                    />
                  </div>
                </div>
                <div className="mt-4">
                  <label htmlFor="message" className="sr-only">
                    Message
                  </label>
                  <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    id="message"
                    name="message"
                    rows={4}
                    className="block w-full border-b border-gray-300 py-3 px-4 placeholder-gray-500 focus:border-indigo-500 focus:ring-indigo-500"
                    placeholder="Message"
                    defaultValue={""}
                  />
                </div>
                {alert && <Alert setAlert={setAlert} />}
                <div className="my-6">
                  <button
                    type="submit"
                    className="shadow-xl inline-flex justify-center items-center rounded-md border border-transparent bg-[#0053DB] py-3 px-6 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <SendMessage />
                    <p className="pl-3">Send Message</p>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto my-16">
        <div className="grid grid-cols-1 gap-12 sm:grid-cols-3 lg:grid-cols-3">
          {features.map((feature) => (
            <div key={feature.name}>
              <div className="flow-root">
                <div className="p-5">
                  <div className="w-full flex justify-center items-center py-5">
                    <feature.icon className="text-white" aria-hidden="true" />
                  </div>
                  <h3 className="text-center pt-4 text-2xl font-normal leading-8  tracking-wide text-[#00174B]">
                    {feature.name}
                  </h3>
                  <p className="pt-2 text-center text-sm font-medium leading-7 text-[#45464F]">
                    {feature.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
