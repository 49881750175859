import React from "react";

export default function message() {
  return (
    <svg
      width="21"
      height="16"
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.4585 16C1.9085 16 1.43783 15.8043 1.0465 15.413C0.654496 15.021 0.458496 14.55 0.458496 14V2C0.458496 1.45 0.654496 0.979333 1.0465 0.588C1.43783 0.196 1.9085 0 2.4585 0H18.4585C19.0085 0 19.4795 0.196 19.8715 0.588C20.2628 0.979333 20.4585 1.45 20.4585 2V14C20.4585 14.55 20.2628 15.021 19.8715 15.413C19.4795 15.8043 19.0085 16 18.4585 16H2.4585ZM10.4585 8.825C10.5418 8.825 10.6292 8.81233 10.7205 8.787C10.8125 8.76233 10.9002 8.725 10.9835 8.675L18.0585 4.25C18.1918 4.16667 18.2918 4.06267 18.3585 3.938C18.4252 3.81267 18.4585 3.675 18.4585 3.525C18.4585 3.19167 18.3168 2.94167 18.0335 2.775C17.7502 2.60833 17.4585 2.61667 17.1585 2.8L10.4585 7L3.7585 2.8C3.4585 2.61667 3.16683 2.61233 2.8835 2.787C2.60016 2.96233 2.4585 3.20833 2.4585 3.525C2.4585 3.69167 2.49183 3.83733 2.5585 3.962C2.62516 4.08733 2.72516 4.18333 2.8585 4.25L9.9335 8.675C10.0168 8.725 10.1045 8.76233 10.1965 8.787C10.2878 8.81233 10.3752 8.825 10.4585 8.825Z"
        fill="white"
      />
    </svg>
  );
}
