import React from "react";

export default function manpower() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_7_186"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="3"
        y="1"
        width="19"
        height="22"
      >
        <path
          d="M6.77789 6.44444C8.00519 6.44444 9.00011 5.44952 9.00011 4.22222C9.00011 2.99492 8.00519 2 6.77789 2C5.55059 2 4.55566 2.99492 4.55566 4.22222C4.55566 5.44952 5.55059 6.44444 6.77789 6.44444Z"
          fill="white"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.7779 6.44444C18.0052 6.44444 19.0001 5.44952 19.0001 4.22222C19.0001 2.99492 18.0052 2 16.7779 2C15.5506 2 14.5557 2.99492 14.5557 4.22222C14.5557 5.44952 15.5506 6.44444 16.7779 6.44444Z"
          fill="white"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4 9.77783H9.55556L8.44444 22.0001H5.11111L4 9.77783ZM14 9.77783H19.5556L20.6667 15.8889H19L18.4444 22.0001H15.1111L14.5556 15.8889H12.8889L14 9.77783Z"
          fill="white"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </mask>
      <g mask="url(#mask0_7_186)">
        <path
          d="M-1.55566 -1.33325H25.111V25.3334H-1.55566V-1.33325Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
