import './App.css';
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Services from "./pages/Services";
import Location from "./components/Location";
import Hero from "./components/Hero";

function App() {
  return (
    <>
    <Navbar/>
    <Hero/>
    <About/>
    <Services/>
    <Contact/>
    <Location/>
    <Footer/>
    </>
  );
}

export default App;
