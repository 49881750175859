import React from "react";

export default function ChatCircle() {
  return (
    <svg
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        width="44"
        height="44"
        rx="22"
        fill="#0053DB"
        fill-opacity="0.1"
      />
      <path
        d="M13.5 22C12.4 22 11.5 21.1 11.5 20V15C11.5 13.9 12.4 13 13.5 13H21.5C22.6 13 23.5 13.9 23.5 15V20C23.5 21.1 22.6 22 21.5 22H19.5V25L16.5 22H13.5ZM31.5 28C32.6 28 33.5 27.1 33.5 26V21C33.5 19.9 32.6 19 31.5 19H25.5V20C25.5 22.2 23.7 24 21.5 24V26C21.5 27.1 22.4 28 23.5 28H25.5V31L28.5 28H31.5Z"
        fill="#0053DB"
      />
    </svg>
  );
}
