import React from "react";

export default function About() {
  return (
    <div id="about" className="mt-32">
      <div className="mx-auto max-w-md px-6 sm:max-w-3xl lg:max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:items-center lg:gap-24">
          <div className="h-full w-full">
            <div className="h-full bg-[url('../public/img/welcome-rectangle.svg')] bg-right-top bg-no-repeat">
              <div className="w-full flex justify-center items-end pt-8">
                <img alt="welcome to staffnow" src="img/about-image.png" />
              </div>
            </div>
          </div>
          <div>
            <h2 className="my-4 text-lg font-semibold tracking-tight text-gray-700 sm:text-lg">
              Who we are
            </h2>
            <h2 className="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
              Welcome to Staffnow
            </h2>
            <p className="mt-6 max-w-3xl text-lg leading-7 text-gray-500">
              Where our team of experienced professionals is dedicated to
              helping businesses reach their marketing and business goals. We
              offer a wide range of marketing services, including market
              research, branding, advertising, public relations, social media
              management, and more.
            </p>
            <div className="mt-6">
              <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-xl drop-shadow-2xl">
                  <a
                    href="/"
                    className="flex w-full items-center justify-center rounded-lg border border-transparent bg-[#0053DB] px-6 py-3 text-base font-medium text-white hover:bg-indigo-700 md:py-3 md:px-6 md:text-lg"
                  >
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
