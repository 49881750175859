import React from "react";

export default function facebook() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.0602 12.0301C24.0602 5.38947 18.6707 0 12.0301 0C5.38947 0 0 5.38947 0 12.0301C0 17.8526 4.13835 22.7008 9.62406 23.8195V15.6391H7.21804V12.0301H9.62406V9.02256C9.62406 6.70075 11.5128 4.81203 13.8346 4.81203H16.8421V8.42105H14.4361C13.7744 8.42105 13.2331 8.96241 13.2331 9.62406V12.0301H16.8421V15.6391H13.2331V24C19.3083 23.3985 24.0602 18.2737 24.0602 12.0301Z"
        fill="white"
      />
    </svg>
  );
}
